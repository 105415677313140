.made {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 90px;

    &-line {
        opacity: 0.11630394;
        width: 100%;
        height: 1.87px;
        border-radius: 1px;
        border: solid 1px #979797;
        background: #d8d8d8;
        transform: scaleY(-1);
    }

    &-section {
        margin-top: 5%;
        max-width: 1392px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;

        &-header {
            font-family: HALIDEROUTER;
            height: 32px;
            color: #ffffff;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            letter-spacing: 1.5px;
        }

        &-small,
        &-other {
            width: 792px;
            margin-top: 5%;
            color: #ffffff;
            font-family: SFPro-text;
            color: #b5b5b5;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.3902439px;
        }

        &-other {
            text-align: center;
        }

        &-photo {
            margin-top: 2.5%;
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;

            img {
                width: 100%;
                position: relative;
                z-index: 5;
            }


        }

        &-app-info {
            margin-top: 1.5%;
            &-header {
                text-align: center;
                color: #ffffff;
                font-family: HALIDEROUTER;
                font-size: 32px;
                font-weight: 400;
                line-height: 43px;
            }

            &-text {
                width: 282px;
                height: 48px;
                color: #bdbdbd;
                font-family: SFPro-text-semi-bold;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;

                span {
                    color: #B7E9D1;
                }
            }

            &-small {}
        }

    }
}