.blog {
    display: flex;
    width: 100%;
    justify-content: center;

    &-section {
        margin-top: 10%;
        width: 1392px;
        display: flex;
        flex-direction: column;
        color: #FFFFFF;

        &-text {
            margin-top: 5%;
            color: #FFFFFF;
            text-align: center;
            font-size: 64px;
            font-family: SFPro-text-semi-bold;
            color: #f0f0f0;
            font-size: 64px;
            font-weight: 400;
            line-height: 85px;

        }

        &-smaller-text {
            margin-bottom: 3%;
            font-size: 18px;
            font-family: SFPro-text;
            width: 50%;
            color: #bebebe;
            // text-align: center;
            align-self: center;
        }
        &-photo {
            display: flex;
            justify-content: center;
            img {
                
            }
        }

        &-blog-post {
            max-width: 656px;
            height: 155px;
            border-radius: 12px;
            border: solid 1px #979797;
            background: linear-gradient(180deg, #2B2B2C, #242425);

            align-self: center;
            display: flex;
            justify-content: space-between;
            padding: 25px;

            &-text {
                height: 100%;
                display: flex;
                flex-direction: column;
                font-family: SFPro-text;

                &-header {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 32px;
                    font-family: HALIDEROUTER
                }

                &-p {
                    margin-top: 15px;
                }

                &-url {
                    height: 100%;
                    display: flex;
                    flex-direction: column-reverse;
                    opacity: 0.5;
                    color: #f0f0f0;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            &-photo {
                margin-left: 25px;
                width: 200px;
                height: 128px;
                border-radius: 4px;
                // border: solid 1px #979797;
                background: #020507;
                align-self: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}