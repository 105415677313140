@font-face {
  font-family: HALIDEROUTER;
  src: url("assets/fonts/HalideRouterNeue.otf") format("opentype");
}

@font-face {
  font-family: SFPro-text;
  src: url("assets/fonts/SFProText-Regular.ttf");
}
@font-face {
  font-family: SFPro-text-bold;
  src: url("assets/fonts/SF-Pro-Display-Bold.otf") format("opentype");
}
@font-face {
  font-family: SFPro-text-semi-bold;
  src: url("assets/fonts/SF-Pro-Display-Semibold.otf") format("opentype");
}
@font-face {
  font-family: SFPro-text-medium;
  src: url("assets/fonts/SF-Pro-Display-Medium.otf") format("opentype");
}



body {
  margin: 0;
  font-family: HALIDEROUTER, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
    height: 100%;
    // overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
