.text {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #0C0C0F;
    &-section {
        width: 100%;
        max-width: 1397px;
        align-content: center;
        background-color: #0C0C0F;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #FFFFFF;

        h1 {
            font-size: 72px;
        }

        h2 {
          font-size: 48px;
            font-weight: 400;
            line-height: 64px;
            text-align: center;
            letter-spacing: 1.1707317px;

            span {
                color: yellow;
            }
        }

        h3 {
            font-size: 24px;
        }

        &-feature {
            max-width: 673px;
            height: 128px;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.58536583px;
            color: #bebebe;
        }

        &-buttons {
            margin-top: 2.5%;
            margin-bottom: 2.5%;
            display: flex;
            justify-content: center;
            
            &-button {
                cursor: pointer;
                color: #000000;
                font-weight: bold;
                font-size: 14px;
                text-align: center;
                justify-content: center;
                display: flex;
                align-items: center;
                width: 188px;
                height: 50px;
                background-color: #FFFE4F;
                border-radius: 12px;
                img {
                    width: 100%;
                }
            }
        }

        .feature {
            font-size: 24px;
            font-family: SFPro-text;
        }
    }
}
