.more {
    margin-top: 250px;
    display: flex;
    width: 100%;
    justify-content: center;
    
    &-section {
        width: 1392px;
        display: flex;
        height: 1000px;
        flex-direction: column;
        background-image: url("../../assets/There_is_more.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: #FFFFFF;

        &-text {
            color: #FFFFFF;
            text-align: center;
            font-size: 48px;
            letter-spacing: 2px;
            font-weight: 0;
            margin-top: 5%;
        }

        &-smaller-text {
            font-size: 18px;
            font-family: SFPro-text;
            color: #FFFFFF;
            // text-align: center;
            align-self: center;
        }

      
    }
}