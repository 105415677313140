.pro {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;

    &-section {
        width: 100%;
        max-width: 1392px;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-right: 10%;
        padding-left: 10%;

        &-text {
            width: 100%;
            z-index: 5;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;

            &-header {
                width: 100%;
                font-size: 64px;
                font-family: SFPro-text-semi-bold;
                z-index: 2;
                position: relative;
                color: #F0F0F0;
                text-align: center;

                span {
                    color: yellow
                }
            }

            &-text {
                color: #bebebe;
                width: 50%;
                font-family: SFPro-text;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
            }
        }

        &-photo {
            height: 100%;
            position: relative;
            z-index: -1;
            width: 60%;

            img {
                height: 100%;
                width: 100%;
            }

        }
    }
}