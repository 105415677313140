.main {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 90px;
    background-color: #171717;
    &-section {
        display: flex;
        width: 992px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: #FFFFFF;
        justify-content: space-between;
        &-object {
            margin-top: 5%;
            &-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-icon {
                    width: 92px;
                    height: 92px;
                  img {
                        width: 100%;
                        height: 100%
                    }
                }

                &-text {
                    margin-top: 15px;
                    color: #f0f0f0;
                    font-family: SFPro-text-bold;
                    font-size: 36px;
                    font-weight: 400;
                    line-height: 48px;
                }
            }

            &-text {
                color: #f0f0f0;
                font-family: SFPro-text-semi-bold;
                font-weight: 400;
                line-height: 24px;
                margin-top: 20px;
                max-width: 432px;
                color: #959595;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}