.footer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 90px;
    padding-bottom: 5%;
    &-section {
        max-width: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;

        &-line {
            opacity: 0.2;
            width: 800px;
            height: 1.87px;
            border-radius: 1px;
            border: solid 1px #979797;
            background: #d8d8d8;
        }
        &-photo {
            display: flex;
            justify-content: center;
            margin-top: 2.5%;
            margin-bottom: 5%;
            img {
                width: 50%;
                height: 100%;
                stroke: black;
         fill: red;
            }
        }

        &-links {
            width: 75%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-link {
                color: #444444;
                font-family: SFPro-text-medium;
                text-align: center;
                &.active {
                    color: #ffffff
                }
            }
        }
    }
}