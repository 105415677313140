.small {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #171717;

    &-section {
        padding-bottom: 10%;
        margin-top: 10%;
        display: flex;
        width: 992px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: #FFFFFF;
        justify-content: space-between;
        &-header {
            font-family: SFPro-text-bold;
            height: 48px;
            color: #f0f0f0;
            font-size: 36px;
            font-weight: 400;
            line-height: 48px;
            text-align: center;
            width: 100%;
        }
        &-holder {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            &-object {
                margin-top: 5%;
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 25%;
                &-icon {
                    width: 46px;
                    height: 46px;
                    border-radius: 12px;
                    img {
                        width: 100%;
                        height: 100%
                    }
                }

                &-text {
                    margin-top: 15px;
                    font-family: SFPro-text-bold;
                    color: #f0f0f0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                }
            }
        }
    }
}