.get {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 90px;
    &-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;

        &-icon {
            width: 138px;
            height: 138px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &-header {
            margin-top: 25px;
            font-family: HALIDEROUTER;
            color: #f0f0f0;
            font-size: 48px;
            font-weight: 400;
            line-height: 64px;
            text-align: center;
            letter-spacing: 1.1707317px;
            width: 100%;
        }

        &-small {
            max-width: 492px;
            margin-top: 5%;
            color: #ffffff;
            font-family: SFPro-text;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.58536583px;
        }

        &-button {
            margin-top: 5%;
            cursor: pointer;
            color: #000000;
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            width: 188px;
            height: 50px;
            background-color: #FFFE4F;
            border-radius: 12px;
            img {
                width: 100%;
            }
        }
        &-press {
            margin-top: 20%;
            color: #959595;
            font-family: SFPro-text;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.4390244px;
            a {
                color: #F6F6F6
            }
        }
     }
}