.designed {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 90px;

    &-section {
        position: relative;
        max-width: 1392px;
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        &-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-family: SFPro-text;
            width: 100%;
            font-size: 18px;

            &-p {
                margin-top: 30px;
                line-height: 24px;
                max-width: 680px;
                text-align: start;
            }
            &-header {
                font-size: 48px;
                font-weight: 400;
                line-height: 32px;
                font-family: SFPro-text-semi-bold;
                text-align: center;
            }
            &-photo {
                width: 200px;
                height: 128px;
                border-radius: 4px;
                border: solid 1px #979797;
                background: #282828;
                align-self: center;
            }
        }
    }
}