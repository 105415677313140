.redisgned {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -1%;
    &-section {
        width: 100%;
        max-width: 1392px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 50%;
        }
        &-text {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            &-header {
                margin-top: 70px;
                font-size: 60px;
                font-family: SFPro-text-semi-bold;
                z-index: 2;
                position: relative;
                color: #F0F0F0;
            }

            &-p {
                margin-top: 35px;
                font-family: SFPro-text;
                font-size: 16px;
                text-align: initial;
                width: 45%;
                color: #bebebe;
            }
        }
    }
}