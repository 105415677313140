.header-section {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #0C0C0F;
    .header {
    width: 1392px;
    display: flex;
        height: 1000px;
    flex-direction: column;
    background-image: url("../../assets/backgrounds/header.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: space-between;
    .header-row {
        padding-top: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .logo {
            height: auto;

            img {
                height: 100%;
                width: auto;
            }

        }
        .get-it {
            max-width: 146px;
            max-height: 146px;
            margin-left: 20%;
            img {
                max-width: 146px;
                max-height: 146px;
                height: auto;
                width: auto;
            }
        }
        .halide {
            // width: 200px;
            img {
                max-height: 80px;
                height: auto;
            }
        }
    }
    .best-camera {
        color: #FFFFFF;
        text-align: center;
        font-size: 72px;
        margin-bottom: 5%;
        letter-spacing: 2px;
        font-weight: 0;
    }
}}